import { useEffect, useState } from "react";
import africa from "../assets/icons/africa.png";
import eu from "../assets/icons/europe.png";
import northAmerica from "../assets/icons/north-america.png";
import asiaPacific from "../assets/icons/asia-pacific.png";
import southAmerica from "../assets/icons/south-america.png";

const GlobeIcons = ({ icon, className }) => {
  const [src, setSrc] = useState();

  useEffect(() => {
    const srcMapping = {
      africa: africa,
      "north-america": northAmerica,
      "asia-pacific": asiaPacific,
      "south-america": southAmerica,
      europe: eu,
    };
    setSrc(srcMapping[icon]);
  }, [icon]);
  return (
    <img
      className={"h-full max-w-16 " + className}
      src={src}
      alt={`${icon} globe icon`}
    />
  );
};

export default GlobeIcons;
