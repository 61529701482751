import { Disclosure } from "@headlessui/react";
import {
  HeartIcon,
  MinusSmallIcon,
  PlusSmallIcon,
  ShareIcon,
  TvIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import logoDesign from "./kazang-logo.jpg";
import logo from "./logo-dark.png";

const faqs = [
  {
    question: "The Greeting",
    answer: (
      <div>
        <p className="my-3 ">
          When you log onto KaZang, the Home page greets you by name, preceded
          by a country flag and the word “Hello” in one of the many languages
          featured on the platform.
        </p>
        <p className="my-3 ">
          The greeting’s language changes each time you sign out and sign back
          in. It might say “Hej,” which is “Hi” in Swedish and Danish and
          pronounced “hey.” Or it might say, “Jambo,” which is hello in Swahili
          – the national language in four nations: Tanzania, Kenya, Uganda and
          the Democratic Republic of Congo. “Olá” is hello in Portuguese, which
          is the national language of Brazil.
        </p>
        <p className="my-3 ">
          If you don’t recognize the language, the country flag is a clue. Can
          you recognize the flag without looking it up?
        </p>
      </div>
    ),
  },
  {
    question: "The Video Carousel",
    answer: (
      <div>
        <p className="my-3 ">
          At the top of the page is a thumbnail featuring Number One songs from
          each of the 37 countries tracked by KaZang. Designers call this
          feature “video carousel,” because it revolves like the carousels you
          ride in amusement parks. Every 3.8 seconds the carousel scrolls to
          reveal a different Number One song.
        </p>
      </div>
    ),
  },
  {
    question: "Category Rows",
    answer: (
      <div>
        <p className="my-3 ">
          Below the carousel are songs sorted into categories. The titles, i.e.,
          “Popular Songs,” “Trending Songs,” and “Popular in Your Network” are
          all self-explanatory.
        </p>
        <p className="my-3 ">
          Below these are the genre listings, i.e., Pop, Rock, Hip-Hop, Rap,
          Reggaeton, K-Pop, Afrobeats, Afropop, and Electronica.
        </p>
        <p className="my-3 ">
          All streaming music platforms have genre listings. KaZang is the only
          one that mingles foreign and domestic artists.
        </p>
        <p className="my-3 ">
          Below the genre listings are rows of Top 10 songs from KaZang
          countries. If you tap or click the words “See More,” KaZang will
          navigate you to a page showing you the country’s Top 100 songs.
        </p>
      </div>
    ),
  },
  {
    question: "How do I play a song?",
    answer: (
      <div>
        <p className="my-3 ">
          To hear a song, tap the thumbnail of a song you want to hear. The
          video will pop up at the bottom of your screen. The controls on the
          video player are self-explanatory. If you want to listen to the song
          without watching the video, tap the TV monitor icon{" "}
          <TvIcon className="inline w-6" /> and the player will disappear.
        </p>
      </div>
    ),
  },
  {
    question: "What happens when I click on the Share icon?",
    answer: (
      <div>
        <p className="my-3 ">
          On the video player control bar, click on the share icon{" "}
          <ShareIcon className="inline w-6" /> to the immediate right of the
          video icon. This will add the song you’re playing to the “Shared
          Songs” list in your “Library.”
        </p>
        <p className="my-3 ">
          Click on the heart icon <HeartIcon className="inline w-6" /> and
          KaZang will add it to your Library’s “Liked Songs.”
        </p>
      </div>
    ),
  },
  {
    question: "How do I share a song?",
    answer: (
      <div>
        <p className="my-3 ">
          From a fan’s perspective, one of the greatest joys in music is sharing
          it with friends. Sharing is also one of the most effective ways to
          discover new songs.
        </p>

        <p className="my-3 ">
          Invite your friends to sign up. New KaZang users must register and
          create a profile. After they complete their profiles, go to the
          sidebar menu, and click on “Find Friends.” This opens a page with a
          search bar. Enter your friends profile names (only one at a time) and
          click “Search.” Their profile will appear in your search results.
          You’re now connected.
        </p>
        <p className="my-3 ">
          Your friends can now see the songs in your Library of Liked Songs and
          Shared Songs.
        </p>
      </div>
    ),
  },
  {
    question: "Why KaZang?",
    answer: (
      <div>
        <p className="my-3 ">
          We created KaZang on an assumption – that people can be enthralled by
          a song even when they don’t understand its lyrics.
        </p>
        <p className="my-3 ">
          Words matter, of course. Some of the world’s greatest pop stars
          include artists who became legends, in part, because of the lyrics
          they wrote.
        </p>
        <p className="my-3 ">
          Taylor Swift, Ed Sheeran, The Beatles, Paul Simon, Carole King, Elton
          John (& Bernie Taupin), The Band, and Buddy Holly are legendary
          songwriters. They are also poets. Their words beguile. Their songs
          connect fans around the world – even when they don’t speak English.
        </p>
        <p className="my-3 ">
          KaZang is looking for artists who sing other languages but have the
          same power to connect the world. KaZang celebrates music – in your
          language, and in languages you don’t speak.
        </p>
      </div>
    ),
  },
  {
    question: "What is the symbolism behind KaZang’s “K”?",
    answer: (
      <div>
        <p className="my-3 ">
          Every time you see the KaZang logo, you’re looking at artistic
          influences from four continents – South America, North America,
          Europe, and South Asia.
        </p>
        <p className="my-3 ">
          Our icon is the letter “K” – but not just any K. In typography, the
          spine of a letter is called the “stem.” The stem of KaZang’s “K” is a
          musical note – a “Bb” on the B Flat Major Scale.
        </p>
        <p className="my-3 ">
          The “arm” and “leg” of the “K” are curved to emulate a classical
          Indian dance pose. The designer created this illustration to help you
          see the source of his inspiration.
        </p>
        <img src={logoDesign} className="py-5"></img>
        <p className="my-3 ">
          What this illustration doesn’t show is the design’s cultural DNA. The
          logo’s project manager is an American who lives in Mexico. She
          commissioned the logo from an Argentinian designer who lives in Spain.
          He looked for inspiration in Indian art.
        </p>
      </div>
    ),
  },
];

export default function FAQs() {
  const navigate = useNavigate();

  const [loggedIn, setLoggedIn] = useState(true);
  useEffect(() => {
    if (!localStorage.getItem("token")) setLoggedIn(false);
  }, []);

  return (
    <div className="flex flex-col h-full max-w-6xl mx-auto max-sm:mt-5">
      <div className="flex flex-col w-full max-w-6xl mx-auto">
        <div className="flex items-center justify-between">
          <div className="my-8 text-5xl font-bold text-white">
            <img className="inline w-[190px] pb-1 mr-3" src={logo}></img> FAQs
          </div>
          {!loggedIn && (
            <div className="flex items-center space-x-3">
              <div
                className="font-semibold cursor-pointer text-light opacity-70"
                onClick={() => navigate("/login")}
              >
                Log in
              </div>
              <div
                className="p-3 px-5 font-semibold text-black bg-white rounded-full cursor-pointer"
                onClick={() => navigate("/register")}
              >
                Sign Up
              </div>
            </div>
          )}
        </div>

        <dl className="mt-10 space-y-6 divide-y divide-white/10">
          {faqs.map((faq) => (
            <Disclosure as="div" key={faq.question} className="pt-6">
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="flex items-start justify-between w-full text-left text-white">
                      <span className="text-lg font-semibold leading-7">
                        {faq.question}
                      </span>
                      <span className="flex items-center ml-6 h-7">
                        {open ? (
                          <MinusSmallIcon
                            className="w-6 h-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <PlusSmallIcon
                            className="w-6 h-6"
                            aria-hidden="true"
                          />
                        )}
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="pr-12 mt-2">
                    <p className="font-medium leading-7 text-light">
                      {faq.answer}
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>

        <div className="mt-10 text-center">
          <div className="my-3 text-3xl font-bold text-light">
            Need to contact us?
          </div>
          <div className="my-3 text-xl font-medium text-light">
            Send suggestions to:{" "}
            <a href="mailto:info@kazang.co">info@kazang.co</a>
          </div>
          <div className="my-3 text-xl font-medium text-light">
            Send requests for technical support here:{" "}
            <a href="mailto:support@kazang.co">support@kazang.co</a>
          </div>
          <div className="my-3 text-xl font-medium text-light">
            We’ll get back to you as soon as we can.
          </div>
        </div>
      </div>
    </div>
  );
}
