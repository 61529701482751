import React, { useRef, useEffect } from "react";
const HorizontalScrollContainer = ({ children }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleWheel = (e) => {
      e.preventDefault();
      if (e.deltaY !== 0 || e.deltaX !== 0) {
        container.scrollLeft += e.deltaY + e.deltaX;
      }
    };

    container.addEventListener("wheel", handleWheel, { passive: false });
    // container.addEventListener("scroll", handleWheel, { passive: false });

    return () => {
      container.removeEventListener("wheel", handleWheel);
      // container.removeEventListener("scroll", handleWheel);
    };
  }, []);

  return (
    <div className="w-full overflow-x-auto relative" ref={containerRef}>
      <div
        style={{
          width: "fit-content",
          overflowX: "scroll",
          whiteSpace: "nowrap",
          display: "flex",
          flexGrow: "1",
          gap: "16px",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default HorizontalScrollContainer;
