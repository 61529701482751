import { useState, useEffect } from "react";
import africa from "../assets/icons/africa.png";
import eu from "../assets/icons/europe.png";
import northAmerica from "../assets/icons/north-america.png";
import asiaPacific from "../assets/icons/asia-pacific.png";
import southAmerica from "../assets/icons/south-america.png";
import genre from "../assets/icons/genre.png";
import { Link } from "react-router-dom";

const Dropdown = ({ items, label, ListComponent, onClickCB }) => {
  const handleClick = (item) => {
    if (!onClickCB) {
      console.warn("no on click callback for dropdown item");
      return;
    }

    onClickCB(item);
  };
  const [src, setSrc] = useState();
  useEffect(() => {
    const srcMapping = {
      africa: africa,
      "north-america": northAmerica,
      "asia-pacific": asiaPacific,
      "south-america": southAmerica,
      europe: eu,
      genres: genre,
    };
    setSrc(srcMapping[label]);
  }, [label]);

  const stylized = (continent) => {
    return continent.split("-").join(" ");
  };

  return (
    <div className="group cursor-pointer m-2 transition-all relative">
      <div className="flex items-center flex-col  group-hover:bg-neutral-600 rounded-t-md p-1 transition-all duration-300">
        <img src={src} alt="" className="h-8 w-8" />
        <div className="text-white max-lg:text-xs capitalize font-medium text-sm text-center">
          {stylized(label)}
        </div>
      </div>
      <div
        className={`
        absolute invisible opacity-0 border-2 border-neutral-500
        flex-col group-hover:flex gap-2 group-hover:opacity-100 group-hover:visible
        py-2 px-0 bg-neutral-700
        z-20 min-w-48
        transition-all
        duration-300
        right-0
        rounded-md text-white rounded-tr-none`}
      >
        {items.map((item, index) => {
          return (
            <Link to={`/`} key={item.id ?? index} state={{ id: item.id }}>
              <ListComponent item={item} />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Dropdown;
