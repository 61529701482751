import { HeartIcon, PlayIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePlayer } from "./App";
import { api } from "./api.service";

export default function Library() {
  const navigate = useNavigate();

  const [data, setData] = useState();
  const [userData, setUserData] = useState();
  const player = usePlayer();

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/public`).then((x) => {
      setData(x);
    });
  }, []);
  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/user`).then((x) => {
      setUserData(x);
    });
  }, []);
  const getYoutubeVideoId = (url) => {
    if (!url) return "";
    return url.split("v=")[1];
  };

  const likeSong = (id, liked) => {
    api(`${process.env.REACT_APP_API_URL}/user/like/${id}/${liked}`).then(
      (x) => {
        api(`${process.env.REACT_APP_API_URL}/user`).then((x) => {
          setUserData(x);
        });
      },
    );
  };

  return (
    <div className="flex flex-col h-full max-sm:mt-5">
      <div className="flex flex-col max-w-6xl mx-auto">
        <div className="mt-5 text-5xl font-black text-white">Library</div>

        <div className="my-2 mt-5 text-xl font-medium text-white">
          Here are all your liked songs. You can play any song to start playing
          your library like a playlist.
        </div>
        <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
          {data?.songs
            ?.filter((x, i) =>
              userData?.likedSongs?.find((y) => y.song == x._id),
            )
            .map((x, i) => (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  player.setSong({ ...x, id: getYoutubeVideoId(x.url) });
                  player.setQueue(
                    data?.songs
                      ?.filter((x, j) =>
                        userData?.likedSongs?.find((y) => y.song == x._id),
                      )
                      .filter((x, j) => j > i)
                      .map((x) => {
                        return { ...x, id: getYoutubeVideoId(x.url) };
                      }),
                  );
                }}
                className="relative overflow-hidden transition duration-300 rounded shadow cursor-pointer select-none bg-neutral-700 group hover:bg-neutral-600"
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    likeSong(x._id, false);
                  }}
                  className="absolute hidden p-1 transition rounded-full right-2 top-2 group-hover:block bg-red-500/50 hover:bg-red-500"
                >
                  <div className="absolute right-0 w-full h-px -rotate-45 bg-black rounded top-4"></div>

                  <HeartIcon className="w-6" />
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    player.setSong({ ...x, id: getYoutubeVideoId(x.url) });
                    player.setQueue(
                      data?.songs
                        ?.filter((x, j) =>
                          userData?.likedSongs?.find((y) => y.song == x._id),
                        )
                        .filter((x, j) => j > i)
                        .map((x) => {
                          return { ...x, id: getYoutubeVideoId(x.url) };
                        }),
                    );
                  }}
                  className="absolute p-2 transition duration-300 scale-100 bg-purple-400 rounded-full shadow opacity-100 cursor-pointer bottom-4 right-4 lg:opacity-0 group-hover:opacity-100 lg:scale-50 group-hover:scale-100 hover:bg-purple-300"
                >
                  <PlayIcon className="w-8 text-white" />
                </div>
                <div className="p-2 text-lg font-semibold tracking-wider text-light">
                  {x.chart}
                </div>
                <div className="flex p-2">
                  <img
                    className="object-cover rounded"
                    src={`https://img.youtube.com/vi/${getYoutubeVideoId(x.url)}/mqdefault.jpg`}
                  ></img>
                </div>
                <div className="p-2">
                  <div className="mb-1 font-semibold text-white truncate">
                    {x.name}
                  </div>
                  <div className="text-xs font-medium truncate text-light">
                    {x.artist}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
