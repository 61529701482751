import rap from "assets/icons/rap.png";
import pop from "assets/icons/pop.png";
import electronica from "assets/icons/electronica.png";
import rock from "assets/icons/rock.png";
import afropop from "assets/icons/afropop.png";
import afrobeats from "assets/icons/afrobeats.png";
import latinpop from "assets/icons/latin-pop.png";
import kpop from "assets/icons/k-pop-white.png";
import hiphop from "assets/icons/hip-hop.png";
import reggaeton from "assets/icons/reggaeton.png";
import movies from "assets/icons/movie.png";
import anime from "assets/icons/anime.png";
import { memo } from "react";

const iconMap = {
  rap,
  pop,
  electronica,
  rock,
  afropop,
  afrobeats,
  "latin pop": latinpop,
  "k-pop": kpop,
  "hip-hop": hiphop,
  reggaeton,
  movies,
  anime,
};
const GenreItem = memo(function Item({ item, isMobile }) {
  const icon = iconMap[item.id];
  return isMobile ? (
    <div className="py-1 cursor-pointer flex items-center">
      <img className="w-7 h-7" src={icon} alt={`${item.label} genre icon`} />
      {item.label}
    </div>
  ) : (
    <div className="hover:bg-purple-600 bg-neutral-700 py-2 px-3 rounded-md cursor-pointer flex gap-2 w-60 items-center">
      <img className="w-7 h-7" src={icon} alt={`${item.label} genre icon`} />
      {item.label}
    </div>
  );
});

export default GenreItem;
