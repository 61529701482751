import React, { Component, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { TbPlayerPause, TbPlayerPlay } from "react-icons/tb";
import { useMediaQuery } from "react-responsive";

const Video = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 720px)" });
  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (isPlaying && isMobile) {
      setTimeout(() => {
        setIsHovering(false);
      }, 1500);
    }
  }, [isPlaying, isMobile]);

  const handleReady = () => {};
  return (
    <div className="relative player-wrapper">
      <div
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={togglePlay}
        className={`${isHovering || !isPlaying ? "opacity-100" : "opacity-0"}
          absolute h-full w-full top-0 left-0
          flex items-center justify-center
          text-6xl text-white bg-gradient-to-r from-opaque-30 via-opaque-50 to-opaque-30`}
      >
        {isPlaying ? <TbPlayerPause /> : <TbPlayerPlay />}
      </div>
      <ReactPlayer
        className="react-player transition-all}"
        // light={<img src="/placeholder.png" width="100%" height="100%" />}
        // https://player.vimeo.com/video/981634404?badge=0&autopause=0&app_id=58479
        url="https://vimeo.com/video/981634404"
        width="100%"
        height="100%"
        style={{ aspectRatio: "16/9", opacity: isReady ? 1 : 0 }}
        playsinline
        onReady={() => setIsReady(true)}
        playing={isPlaying}
        config={{
          playerOptions: {
            title: false,
            color: "#6515DD",
          },
        }}
        controls={false}
      />
    </div>
  );
};
// class Video extends Component {
//   render() {
//     return (
//       <div className="relative player-wrapper">
//         <ReactPlayer
//           className="react-player"
//           // https://player.vimeo.com/video/981634404?badge=0&autopause=0&app_id=58479
//           url="https://vimeo.com/video/981634404"
//           width="100%"
//           height="100%"
//           style={{ aspectRatio: "16/9" }}
//           playsinline
//           config={{
//             playerOptions: {
//               title: false,
//               color: "#6515DD",
//             },
//           }}
//           controls={false}
//         />
//       </div>
//     );
//   }
// }

export default Video;
