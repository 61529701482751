import { ArrowUpIcon } from "@heroicons/react/20/solid";
import { IKContext, IKUpload } from "imagekitio-react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import ImageViewer from "../../components/image-viewer";

export default function ImageField({
  label,
  value,
  onChange,
  className,
  inputClass,
  labelClass,
}) {
  const [uploading, setUploading] = useState(false);

  return (
    <div className={className}>
      <ToastContainer position="top-center" autoClose={1000} />
      <label
        className={`block text-sm font-medium leading-6 text-white ${labelClass}`}
      >
        {label}
      </label>
      <div className="mt-2">
        <div className="flex space-x-4">
          <div className="shadow-lg w-28 h-28 relative rounded-full overflow-hidden my-2 bg-neutral-200 flex items-center justify-center">
            {uploading && (
              <div className="absolute mx-auto">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <style>
                    {
                      ".spinner_ajPY{transform-origin:center;animation:spinner_AtaB .75s infinite linear}@keyframes spinner_AtaB{100%{transform:rotate(360deg)}}"
                    }
                  </style>
                  <path
                    d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                    opacity=".25"
                  />
                  <path
                    d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
                    class="spinner_ajPY"
                  />
                </svg>
              </div>
            )}

            {value && (
              <div class="absolute inset-0 z-10">
                <ImageViewer
                  className={`h-full w-full object-cover ${uploading ? "opacity-20" : ""}`}
                  image={value}
                ></ImageViewer>
              </div>
            )}

            {!value && (
              <label class="text-center p-3 w-full h-full flex flex-col justify-center cursor-pointer">
                {!uploading && (
                  <ArrowUpIcon className="w-8 mx-auto text-white"></ArrowUpIcon>
                )}
                {!uploading && (
                  <p class="text-xs text-white">click to upload image</p>
                )}

                <IKContext
                  publicKey="public_j0dr6pc811jSC5KpPfqFZbC/ya4="
                  urlEndpoint="https://ik.imagekit.io/1thczwpod"
                  authenticationEndpoint={`${process.env.REACT_APP_API_URL}/public/image-upload-auth`}
                >
                  <IKUpload
                    className="absolute w-0 h-0 opacity-0"
                    validateFile={(file) => {
                      file.size > 2000000
                        ? toast("File size too big. Images must be under 2mb")
                        : console.log("Uploading");
                      return file.size < 2000000;
                    }}
                    onSuccess={(res) => {
                      onChange(res.filePath);
                      setUploading(false);
                    }}
                    onUploadStart={() => setUploading(true)}
                  />
                </IKContext>
              </label>
            )}
          </div>

          {value && (
            <label className="w-28 sidebar-btn text-center flex items-center overflow-hidden">
              <div className="bg-gradient-to-b from-neutral-50 to-neutral-100 rounded p-3 text-sm font-medium text-zinc-700 hover:text-zinc-900">
                Replace
              </div>
              <IKContext
                publicKey="public_j0dr6pc811jSC5KpPfqFZbC/ya4="
                urlEndpoint="https://ik.imagekit.io/1thczwpod"
                authenticationEndpoint={`${process.env.REACT_APP_API_URL}/public/image-upload-auth`}
              >
                <IKUpload
                  className="absolute w-0 h-0 opacity-0"
                  validateFile={(file) => {
                    file.size > 2000000
                      ? toast("File size too big. Images must be under 2mb")
                      : console.log("Uploading");
                    return file.size < 2000000;
                  }}
                  onSuccess={(res) => {
                    onChange(res.filePath);
                    setUploading(false);
                  }}
                  onUploadStart={() => setUploading(true)}
                />
              </IKContext>
            </label>
          )}
        </div>
      </div>
    </div>
  );
}
