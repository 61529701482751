import { useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from 'react-router-dom';
import Video from './components/video';
import logo from './logo-dark.png';
import {api} from "./api.service";
import {toast} from "react-toastify";
import {loadStripe} from "@stripe/stripe-js";
import Loading from "./components/loading";

export default function Subscription() {
    const navigate = useNavigate();
    const [loggedIn, setLoggedIn] = useState(true)
    const [userData, setUserData] = useState();
    const [paymentProcessing, setPaymentProcessing] = useState(false);

    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

    useEffect(() => {

        if (!localStorage.getItem('token')) {
            setLoggedIn(false)
        } else {
            api(`${process.env.REACT_APP_API_URL}/user`).then(x => { setUserData(x) });
        }

    }, [])

    if (paymentProcessing) return <Loading></Loading>

    return (<section className="flex flex-col items-center p-12 bg-neutral-800 h-max my-20">
        <div className="flex flex-col items-center text-center">
            <h2 className="text-3xl text-white">
                <span> </span>
                <span> Upgrade to Premium</span>
                <span> </span>
            </h2>
            <p className="mt-2.5 text-white">
                <span> </span>
                <span>
            {" "}
                    Get exclusive new features and benefits with KaZang Premium.
          </span>
                <span> </span>
            </p>
            <div className="flex justify-center mt-8"/>
            <div className="flex flex-row">
                <div className="p-5 text-center">
                    <img
                        loading="lazy"
                        alt=""
                        srcSet="/playlistNote.png"
                        className="object-cover m-auto mb-5 rounded-xl h-[140px] w-[140px]"
                    />
                    <h3 className="text-white">
                        <span/>
                        <span><b>Create Group Playlists</b></span>
                        <span/>
                    </h3>
                    <p className="text-white">
                        <span/>
                        <span> Create playlists and add your friends</span><br/>
                        <span> to easily share music</span>
                        <span/>
                    </p>
                </div>
                <div className="p-5 text-center">
                    <img
                        loading="lazy"
                        alt=""
                        srcSet="/addSongNote.png"
                        className="object-cover m-auto mb-5 rounded-xl h-[140px] w-[140px]"
                    />
                    <h3 className="text-white">
                        <span/>
                        <span><b>Add Songs To KaZang</b></span>
                        <span/>
                    </h3>
                    <p className="text-white">
                        <span/>
                        <span>Add your favorite songs to KaZang</span><br/>
                        <span>and share it with the world</span>
                        <span/>
                    </p>
                </div>
            </div>
            {!userData?.subActive && (
                <button
                    className="px-5 mt-5 text-2xl text-center text-white bg-purple-700 rounded-md border-green-500 cursor-pointer border-[none] h-[50px] w-[230px]"
                    onClick={() => {
                        if (!userData) {
                            navigate('/login');
                        } else if (!userData.subActive) {
                            api(`${process.env.REACT_APP_API_URL}/user/create-checkout-session`).then(async x => {
                                console.log(userData.subActive);

                                // Processing
                                setPaymentProcessing(true);

                                // Get Stripe.js instance
                                const stripe = await stripePromise;

                                // When the customer clicks on the button, redirect them to Checkout.
                                const result = await stripe.redirectToCheckout({
                                    sessionId: x.id,
                                });

                                if (result.error) {
                                    // If `redirectToCheckout` fails due to a browser or network
                                    // error, display the localized error message to your customer.
                                    toast.error(result.error.message);
                                    setPaymentProcessing(false);
                                }
                            });
                        }
                    }}>
                    Upgrade
                </button>
            )}
            {userData?.subActive && (
                <span className="text-white"><u>You are already subscribed!</u></span>
                )}
        </div>
    </section>)
}