import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import icon from "../KaZang-Icon-Filled.png";
import { SidebarLink } from "./sidebar-link";
import {
  TbUserCircle,
  TbSearch,
  TbMusicPlus,
  TbHome2,
  TbUsers,
  TbPlaylist,
  TbLibrary,
  TbInfoCircle,
  TbQuestionMark,
} from "react-icons/tb";
import binoculars from "../assets/icons/binoculars.png";
import ImageViewer from "./image-viewer";

export const Sidebar = ({ userData }) => {
  const [sidebarOpen, setSidebarOpen] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  const Explore = () => {
    return (
      <img
        src={binoculars}
        className="object-fill max-h-6"
        alt="binoculars icon, explore page"
      />
    );
  };

  const ProfilePicture = (picture) => {
    return (
      <ImageViewer
        style={{ height: "28px", width: "28px" }}
        className="w-24 h-24 max-w-24 rounded-full shrink-0"
        image={picture}
      />
    );
  };
  return (
    <div
      id="sidebar"
      className={`
        relative
        flex
        h-full
        flex-col
        md:flex-row
        min-h-full
        z-20
        border-0
        outline-none
        bg-neutral-800
        rounded
        transition-all
        max-md:hidden
        min-w-24
        w-24`}
    >
      <div
        className={`${sidebarOpen ? "w-[30vw]" : "w-full"}
          z-0 absolute top-0 left-0 flex h-full
          rounded
          md:bg-gradient-to-r from-neutral-800 via-neutral-800 to-transparent
          transition-all duration-500`}
      >
        <div
          className={`
        ${sidebarOpen ? " w-full overflow-hidden" : "w-24 "}
        flex flex-col
        flex-start
        flex-1
        py-3
        pl-4
        h-full
        z-10
        `}
          onMouseOver={() => setSidebarOpen(true)}
          onMouseOut={() => setSidebarOpen(false)}
        >
          <img
            src={icon}
            className="w-14 max-w-14 mb-10 z-10"
            alt="kazang logo - large K"
          ></img>
          <div
            className={`
        flex flex-col flex-1
        text-left
        transition-all
        duration-500
        justify-start gap-4
        ${sidebarOpen ? "pl-0" : "pl-2"}
        `}
          >
            <div>
              <SidebarLink
                path={"/profile/me"}
                text={"My Profile"}
                navigate={navigate}
                icon={
                  userData?.profilePicture
                    ? () => ProfilePicture(userData?.profilePicture)
                    : TbUserCircle
                }
                sidebarOpen={sidebarOpen}
              />
              <SidebarLink
                path={"/search"}
                text={"Search"}
                navigate={navigate}
                icon={TbSearch}
                sidebarOpen={sidebarOpen}
              />
              <SidebarLink
                path={"/"}
                text={"Home"}
                navigate={navigate}
                icon={TbHome2}
                sidebarOpen={sidebarOpen}
              />
              <SidebarLink
                path={"/friends"}
                text={"Friends"}
                navigate={navigate}
                icon={TbUsers}
                sidebarOpen={sidebarOpen}
              />
            </div>
            <div>
              <SidebarLink
                path={"/playlists"}
                text={"Playlists"}
                navigate={navigate}
                icon={TbPlaylist}
                sidebarOpen={sidebarOpen}
              />
              <SidebarLink
                path={"/add-song"}
                text={"Add a Song"}
                navigate={navigate}
                icon={TbMusicPlus}
                sidebarOpen={sidebarOpen}
              />
              <SidebarLink
                path={"/library"}
                text={"Library"}
                navigate={navigate}
                icon={TbLibrary}
                sidebarOpen={sidebarOpen}
              />
              <SidebarLink
                path={"/"}
                text={"Explore"}
                navigate={navigate}
                icon={Explore}
                sidebarOpen={sidebarOpen}
              />
              {/* <SidebarLink
              path={"/charts"}
              text={"Charts"}
              navigate={navigate}
              icon={TbChartArcs}
              sidebarOpen={sidebarOpen}
            /> */}
            </div>

            <div>
              <SidebarLink
                path={"/faqs"}
                text={"FAQs"}
                navigate={navigate}
                icon={TbQuestionMark}
                sidebarOpen={sidebarOpen}
                onMouseEnter={() => setSidebarOpen(true)}
                onMouseLeave={() => setSidebarOpen(false)}
              />
              <SidebarLink
                path={"/about"}
                text={"About Kazang"}
                navigate={navigate}
                icon={TbInfoCircle}
                sidebarOpen={sidebarOpen}
                onMouseEnter={() => setSidebarOpen(true)}
                onMouseLeave={() => setSidebarOpen(false)}
              />
              {/* <SidebarLink
              path={"/settings"}
              text={"Explore"}
              navigate={navigate}
              icon={TbSettings}
              sidebarOpen={sidebarOpen}
              onMouseEnter={() => setSidebarOpen(true)}
              onMouseLeave={() => setSidebarOpen(false)}
            />
            <SidebarLink
              path={"/logout"}
              text={"Logout"}
              navigate={navigate}
              icon={TbLogout}
              sidebarOpen={sidebarOpen}
              onMouseEnter={() => setSidebarOpen(true)}
              onMouseLeave={() => setSidebarOpen(false)}
            /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
