import { memo } from "react";
import Flag from "react-flagkit";
const CountryItem = memo(function Item({ item }) {
  return (
    <div className="hover:bg-purple-600 bg-neutral-700 py-2 px-3 rounded-md cursor-pointer flex gap-2 w-60 items-center">
      <Flag
        country={item.shortCode}
        size={20}
        // className={`${welcomeFlag ?? "hidden"} pr-4 inline-block`}
      ></Flag>
      {item.countryLabel}
    </div>
  );
});

export default CountryItem;
