import * as Icons from "react-icons/tb";
const MobileNavItem = ({ icon, text, path }) => {
  const Icon = Icons[icon];
  return (
    <li className="w-full text-center">
      <a
        href={path}
        className="flex flex-row items-center text-xl gap-2 w-full"
      >
        <span>
          {" "}
          <Icon />{" "}
        </span>
        {text}
      </a>
    </li>
  );
};

export default MobileNavItem;
