import { PlayIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "./api.service";
import { usePlayer } from "./App";
import MemberCard from "./components/member-card";
import FormModal from "./forms/form-modal";
import { toast } from "react-toastify";

export default function Playlist() {
  const [data, setData] = useState();
  const [addMemberOpen, setAddMemberOpen] = useState(false);
  const [ownUserData, setOwnUserData] = useState();

  const navigate = useNavigate();
  const params = useParams();
  const player = usePlayer();

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/user/playlist/${params.id}`).then(
      (x) => {
        setData(x);
      },
    );
  }, []);
  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/user`).then((x) => {
      setOwnUserData(x);
    });
  }, []);

  const getYoutubeVideoId = (url) => {
    return url?.split("v=")[1];
  };

  const deleteSong = (songId) => {
    api(
      `${process.env.REACT_APP_API_URL}/user/playlist/remove-song/${params.id}/${songId}`,
    )
      .then((x) => {
        api(`${process.env.REACT_APP_API_URL}/user/playlist/${params.id}`).then(
          (x) => {
            setData(x);
          },
        );
      })
      .then((x) => toast.info("Song removed"));
  };

  const addMember = (id) => {
    if (ownUserData.subActive) {
      api(
        `${process.env.REACT_APP_API_URL}/user/playlist/add-member/${params.id}/${id}`,
      )
        .then((x) => {
          api(
            `${process.env.REACT_APP_API_URL}/user/playlist/${params.id}`,
          ).then((x) => {
            setData(x);
          });
        })
        .then((x) => toast.success("Member added"));
    } else {
      navigate("/subscription");
    }
  };

  const deleteMember = (id) => {
    api(
      `${process.env.REACT_APP_API_URL}/user/playlist/remove-member/${params.id}/${id}`,
    )
      .then((x) => {
        api(`${process.env.REACT_APP_API_URL}/user/playlist/${params.id}`).then(
          (x) => {
            setData(x);
          },
        );
      })
      .then((x) => toast.info("Memeber removed from " + data?.name));
  };
  const isOwner = data?.owner == ownUserData?._id;
  return (
    <div className="flex flex-col h-full max-w-6xl mx-auto max-sm:mt-5">
      <FormModal open={addMemberOpen} setOpen={setAddMemberOpen}>
        <div className="flex flex-col p-5 bg-slate-800">
          <div className="mb-5 text-xl font-bold text-white">
            Add Friends to this Playlist
          </div>

          {!ownUserData?.contacts?.filter(
            (x) =>
              !data?.members.find((y) => y._id == x._id) &&
              x._id != ownUserData._id,
          ).length && (
            <div className="my-5 text-lg font-medium text-center text-white">
              You've added all your friends to this playlist!
            </div>
          )}

          {ownUserData?.contacts
            ?.filter(
              (x) =>
                !data?.members.find((y) => y._id == x._id) &&
                x._id != ownUserData._id,
            )
            .map((x) => (
              <div
                onClick={() => addMember(x._id)}
                className="flex justify-between p-2 font-medium text-white transition border-b rounded cursor-pointer select-none group hover:bg-slate-700 border-slate-900"
              >
                <div>{x.name}</div>
                <div>
                  <XMarkIcon className="w-6 transition rotate-45 group-hover:scale-110" />
                </div>
              </div>
            ))}
        </div>
      </FormModal>
      <div className="mt-5 text-5xl font-black text-white">{data?.name}</div>

      <div className="mt-8 mb-3 text-xl font-semibold text-white">Members</div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {data?.members?.map((x) => (
          <MemberCard
            displayDelete={isOwner}
            x={x}
            remove={() => deleteMember(x._id)}
          />
        ))}
        {isOwner && (
          <div
            className="flex flex-col items-center p-3 py-8 text-xl text-center text-white rounded shadow cursor-pointer hover:bg-neutral-800 bg-neutral-700"
            onClick={() => {
              if (ownUserData.subActive) {
                setAddMemberOpen(true);
              } else {
                navigate("/subscription");
              }
            }}
          >
            <div> Add a friend to this playlist</div>
            <div>
              <XMarkIcon className="w-10 mx-auto rotate-45" />
            </div>
          </div>
        )}
      </div>

      <div className="flex flex-col mt-10 space-y-1">
        {data?.songs?.map((x, i) => (
          <div
            onClick={(e) => {
              e.stopPropagation();
              player.setSong({ ...x, id: getYoutubeVideoId(x.url) });
            }}
            className="flex items-center justify-between p-3 font-medium text-white transition border-b rounded-lg cursor-pointer border-neutral-600 hover:bg-slate-800 bg-neutral-700"
          >
            <div>
              <div className="text-xs font-light tracking-wide text-light">
                {x.chart}
              </div>
              <div className="font-medium text-white">{x.name}</div>

              <div className="text-sm font-medium text-light">{x.artist}</div>
            </div>
            <div className="flex space-x-4">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  player.setSong({ ...x, id: getYoutubeVideoId(x.url) });
                  player.setQueue(
                    data?.songs
                      ?.filter((x, j) => j > i)
                      .map((x) => {
                        return { ...x, id: getYoutubeVideoId(x.url) };
                      }),
                  );
                }}
                className="p-2 bg-purple-400 rounded-full shadow opacity-100 cursor-pointer hover:bg-purple-500 bottom-4 right-4"
              >
                <PlayIcon className="w-8 text-white" />
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  deleteSong(x._id);
                }}
                className="p-1 my-auto bg-red-400 rounded-full shadow opacity-100 cursor-pointer hover:bg-red-500 bottom-4 right-4"
              >
                <XMarkIcon className="w-6 text-white" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
