export const genres = [
  {
    id: "pop",
    label: "Pop",
    icon: "assets/icons/pop.png",
  },
  {
    id: "rock",
    label: "Rock",
    icon: "assets/icons/rock.png",
  },
  {
    id: "hip-hop",
    label: "Hip-hop",
    icon: "assets/icons/hip-hop.png",
  },
  {
    id: "rap",
    label: "Rap",
    icon: "assets/icons/rap.png",
  },
  {
    id: "reggaeton",
    label: "Reggaeton",
    icon: "assets/icons/reggaeton.png",
  },
  {
    id: "k-pop",
    label: "K-Pop",
    icon: "assets/icons/k-pop-white.png",
  },
  {
    id: "latin pop",
    label: "Latin Pop",
    icon: "assets/icons/latin-pop.png",
  },
  {
    id: "afrobeats",
    label: "Afrobeats",
    icon: "assets/icons/afrobeats.png",
  },
  {
    id: "afropop",
    label: "Afropop",
    icon: "assets/icons/afropop.png",
  },
  {
    id: "electronica",
    label: "Electronica",
    icon: "assets/icons/electronica.png",
  },
  {
    id: "movies",
    label: "Movies",
    icon: "assets/icons/movie.png",
  },
  {
    id: "anime",
    label: "Anime",
    icon: "assets/icons/anime.png",
  },
];

export const countries = {
  africa: [
    {
      countryLabel: "Ghana",
      id: "ghana",
      shortCode: "GH",
    },
    {
      countryLabel: "Kenya",
      id: "kenya",
      shortCode: "KE",
    },
    {
      countryLabel: "Nigeria",
      id: "nigeria",
      shortCode: "NG",
    },
    {
      countryLabel: "South Africa",
      id: "south africa",
      shortCode: "ZA",
    },
    {
      countryLabel: "Tanzania",
      id: "tanzania",
      shortCode: "TZ",
    },
    {
      countryLabel: "Uganda",
      id: "uganda",
      shortCode: "UG",
    },
    {
      countryLabel: "Zimbabwe",
      id: "zimbabwe",
      shortCode: "ZW",
    },
  ],
  "asia-pacific": [
    {
      countryLabel: "Australia",
      id: "australia",
      shortCode: "AU",
    },
    {
      countryLabel: "China",
      id: "china",
      shortCode: "CN",
    },
    {
      countryLabel: "India",
      id: "india",
      shortCode: "IN",
    },
    {
      countryLabel: "Indonesia",
      id: "indonesia",
      shortCode: "ID",
    },
    {
      countryLabel: "New Zealand",
      id: "new zealand",
      shortCode: "NZ",
    },
    {
      countryLabel: "Pakistan",
      id: "pakistan",
      shortCode: "PK",
    },
    {
      countryLabel: "Philippines",
      id: "philippines",
      shortCode: "PH",
    },
    {
      countryLabel: "Singapore",
      id: "singapore",
      shortCode: "SI",
    },
    {
      countryLabel: "Japan",
      id: "japan",
      shortCode: "JP",
    },
    {
      countryLabel: "South Korea",
      id: "south korea",
      shortCode: "KR",
    },
    {
      countryLabel: "Vietnam",
      id: "vietnam",
      shortCode: "VN",
    },
  ],
  "south-america": [
    {
      countryLabel: "Argentina",
      id: "argentina",
      shortCode: "AR",
    },
    {
      countryLabel: "Brazil",
      id: "brazil",
      shortCode: "BR",
    },
    {
      countryLabel: "Chile",
      id: "chile",
      shortCode: "CL",
    },
    {
      countryLabel: "Colombia",
      id: "colombia",
      shortCode: "CO",
    },
    {
      countryLabel: "Peru",
      id: "peru",
      shortCode: "PE",
    },
  ],
  "north-america": [
    {
      countryLabel: "Canada",
      id: "canada",
      shortCode: "CA",
    },
    {
      countryLabel: "Cuba",
      id: "cuba",
      shortCode: "CU",
    },
    {
      countryLabel: "Dominican Republic",
      id: "dominican republic",
      shortCode: "DO",
    },
    {
      countryLabel: "El Salvador",
      id: "el salvador",
      shortCode: "SV",
    },
    {
      countryLabel: "Jamaica",
      id: "jamaica",
      shortCode: "JM",
    },
    {
      countryLabel: "Mexico",
      id: "mexico",
      shortCode: "MX",
    },
    {
      countryLabel: "United States",
      id: "united states",
      shortCode: "US",
    },
  ],
  europe: [
    {
      countryLabel: "France",
      id: "france",
      shortCode: "FR",
    },
    {
      countryLabel: "Germany",
      id: "germany",
      shortCode: "DE",
    },
    {
      countryLabel: "Italy",
      id: "italy",
      shortCode: "IT",
    },
    {
      countryLabel: "Netherlands",
      id: "netherlands",
      shortCode: "NL",
    },
    {
      countryLabel: "Norway",
      id: "norway",
      shortCode: "NO",
    },
    {
      countryLabel: "Spain",
      id: "spain",
      shortCode: "ES",
    },
    {
      countryLabel: "Sweden",
      id: "sweden",
      shortCode: "SE",
    },
    {
      countryLabel: "United Kingdom",
      id: "united kingdom",
      shortCode: "GB",
    },
  ],
};

export const CONTINENT_GROUPS = {
  Africa: 1,
  AsiaPacific: 2,
  SAmerica: 3,
  NAmerica: 4,
  EU: 5,
};
export const countriesByContinent = {
  afghanistan: "Asia Pacific",
  albania: "Europe",
  algeria: "Africa",
  andorra: "Europe",
  angola: "Africa",
  antiguaAndBarbuda: "North America",
  argentina: "South America",
  armenia: "Asia Pacific",
  australia: "Asia Pacific",
  austria: "Europe",
  azerbaijan: "Asia Pacific",
  bahamas: "North America",
  bahrain: "Asia Pacific",
  bangladesh: "Asia Pacific",
  barbados: "North America",
  belarus: "Europe",
  belgium: "Europe",
  belize: "North America",
  benin: "Africa",
  bhutan: "Asia Pacific",
  bolivia: "South America",
  bosniaandherzegovina: "Europe",
  botswana: "Africa",
  brazil: "South America",
  brunei: "Asia Pacific",
  bulgaria: "Europe",
  burkinafaso: "Africa",
  burundi: "Africa",
  caboVerde: "Africa",
  cambodia: "Asia Pacific",
  cameroon: "Africa",
  canada: "North America",
  centralafricanrepublic: "Africa",
  chad: "Africa",
  chile: "South America",
  china: "Asia Pacific",
  colombia: "South America",
  comoros: "Africa",
  congodemocraticrepublic: "Africa",
  congorepublic: "Africa",
  costaRica: "North America",
  cotedivoire: "Africa",
  croatia: "Europe",
  cuba: "North America",
  cyprus: "Asia Pacific",
  czechrepublic: "Europe",
  denmark: "Europe",
  djibouti: "Africa",
  dominica: "North America",
  dominicanrepublic: "North America",
  ecuador: "Asia Pacific",
  egypt: "Africa",
  elsalvador: "North America",
  equatorialguinea: "Africa",
  eritrea: "Africa",
  estonia: "Europe",
  eswatini: "Africa",
  ethiopia: "Africa",
  fiji: "Asia Pacific",
  finland: "Europe",
  france: "Europe",
  gabon: "Africa",
  gambia: "Africa",
  georgia: "Asia Pacific",
  germany: "Europe",
  ghana: "Africa",
  greece: "Europe",
  grenada: "North America",
  guatemala: "North America",
  guinea: "Africa",
  guineabissau: "Africa",
  guyana: "South America",
  haiti: "North America",
  honduras: "North America",
  hungary: "Europe",
  iceland: "Europe",
  india: "Asia Pacific",
  indonesia: "Asia Pacific",
  iran: "Asia Pacific",
  iraq: "Asia Pacific",
  ireland: "Europe",
  israel: "Asia Pacific",
  italy: "Europe",
  jamaica: "North America",
  japan: "Asia Pacific",
  jordan: "Asia Pacific",
  kazakhstan: "Asia Pacific",
  kenya: "Africa",
  kiribati: "Asia Pacific",
  northkorea: "Asia Pacific",
  southkorea: "Asia Pacific",
  kosovo: "Europe",
  kuwait: "Asia Pacific",
  kyrgyzstan: "Asia Pacific",
  laos: "Asia Pacific",
  latvia: "Europe",
  lebanon: "Asia Pacific",
  lesotho: "Africa",
  liberia: "Africa",
  libya: "Africa",
  liechtenstein: "Europe",
  lithuania: "Europe",
  luxembourg: "Europe",
  madagascar: "Africa",
  malawi: "Africa",
  malaysia: "Asia Pacific",
  maldives: "Asia Pacific",
  mali: "Africa",
  malta: "Europe",
  marshallislands: "Asia Pacific",
  mauritania: "Africa",
  mauritius: "Africa",
  mexico: "North America",
  micronesia: "Asia Pacific",
  moldova: "Europe",
  monaco: "Europe",
  mongolia: "Asia Pacific",
  montenegro: "Europe",
  morocco: "Africa",
  mozambique: "Africa",
  myanmar: "Asia Pacific",
  namibia: "Africa",
  nauru: "Asia Pacific",
  nepal: "Asia Pacific",
  netherlands: "Europe",
  newzealand: "Asia Pacific",
  nicaragua: "North America",
  niger: "Africa",
  nigeria: "Africa",
  northmacedonia: "Europe",
  norway: "Europe",
  oman: "Asia Pacific",
  pakistan: "Asia Pacific",
  palau: "Asia Pacific",
  panama: "North America",
  papuanewguinea: "Asia Pacific",
  paraguay: "South America",
  peru: "South America",
  philippines: "Asia Pacific",
  poland: "Europe",
  portugal: "Europe",
  qatar: "Asia Pacific",
  romania: "Europe",
  russia: "Europe",
  rwanda: "Africa",
  saintkittsandnevis: "North America",
  saintLucia: "North America",
  saintvincentandthegrenadines: "North America",
  samoa: "Asia Pacific",
  sanmarino: "Europe",
  saotomeandprincipe: "Africa",
  saudiarabia: "Asia Pacific",
  senegal: "Africa",
  serbia: "Europe",
  seychelles: "Africa",
  sierraleone: "Africa",
  singapore: "Asia Pacific",
  slovakia: "Europe",
  slovenia: "Europe",
  solomonislands: "Asia Pacific",
  somalia: "Africa",
  southafrica: "Africa",
  southsudan: "Africa",
  spain: "Europe",
  srilanka: "Asia Pacific",
  sudan: "Africa",
  suriname: "Asia Pacific",
  sweden: "Europe",
  switzerland: "Europe",
  syria: "Asia Pacific",
  taiwan: "Asia Pacific",
  tajikistan: "Asia Pacific",
  tanzania: "Africa",
  thailand: "Asia Pacific",
  timorleste: "Asia Pacific",
  togo: "Africa",
  tonga: "Asia Pacific",
  trinidadandtobago: "North America",
  tunisia: "Africa",
  turkey: "Asia Pacific",
  turkmenistan: "Asia Pacific",
  tuvalu: "Asia Pacific",
  uganda: "Africa",
  ukraine: "Europe",
  unitedarabemirates: "Asia Pacific",
  unitedkingdom: "Europe",
  unitedstates: "North America",
  uruguay: "Asia Pacific",
  uzbekistan: "Asia Pacific",
  vanuatu: "Asia Pacific",
  venezuela: "South America",
  vietnam: "Asia Pacific",
  yemen: "Asia Pacific",
  zambia: "Africa",
  zimbabwe: "Africa",
  global: "Global",
};
